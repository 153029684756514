
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import ContactsList from 'Components/adept/contacts/List';

export default class PageAdeptContacts extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    defaultQuery = {
        tab: null,
    };

    render() {
        const { location, history } = this.props;

        return (
            <StyledComponent
                className="page-adept-contacts"
                styles={require('./styles')}
            >
                <TabNavigation
                    location={location}
                    history={history}
                    headline="Kontakty"
                    tabs={[{
                        key: 'contactsList',
                        label: 'Lista kontaktów',
                        children: (
                            <ContactsList
                                location={location}
                                history={history}
                            />
                        ),
                    }]}
                />
            </StyledComponent>
        );
    }
}
