import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { getFullName as getUserFullName } from 'Utils/user';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layout/panel/PaginatedList';
import PaginatedListElement from 'Components/layout/panel/PaginatedListElementV2';

export default class AdeptContactsList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
        }).isRequired,
        contacts: PropTypes.object.isRequired,
    };
    static defaultProps = {
        predefinedQuery: {},
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        timestamp: undefined,
        enabled: true,
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    render() {
        const { contacts, location, history } = this.props;

        const allContacts = {
            ...contacts,
            elements: [
                {
                    avatar: null,
                    avatarS3Path: null,
                    email: 'bok@fitadept.com',
                    id: 'bok',
                    name: 'Biuro Obsługi Klienta',
                    surname: '',
                }, {
                    avatar: null,
                    avatarS3Path: null,
                    email: 'diet@fitadept.com',
                    id: 'diet',
                    name: 'Dietetycy FitAdept',
                    surname: '',
                },
                ...contacts.elements,
            ],
        };

        return (
            <StyledComponent
                className="adept-contacts-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={allContacts}
                    queryConfig={this.getQueryConfig()}
                    styleProps={{
                        styleVersion: 2,
                    }}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={getUserFullName(element, 'nameSurname').label}       
                            subtitle={
                                <>
                                    {element.phone && (
                                        <div className="contact-option-container phone">
                                            <a className="contact-option" href={`tel:${element.phone}`}> <FontAwesomeIcon icon={['fa', 'phone']} /> {element.phone}</a>
                                        </div>
                                    )}
                                    {element.email && (
                                        <div className="contact-option-container email">
                                            <a className="contact-option" href={`mailto:${element.email}`}> <FontAwesomeIcon icon={['fa', 'envelope']} /> {element.email}</a>
                                        </div>
                                    )}
                                </>
                            }
                        />
                    )}
                />
            </StyledComponent>
        );
    }
}
