import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .contact-option-container {
            .contact-option {
                .svg-inline--fa {
                    margin-right: .25em;
                    color: #767676;
                }

                color: ${variables.dpc_fontDark};

            }

            &:not(:last-of-type) {
                margin-bottom: .25em;
            }
        }
    `;
